import styled from 'styled-components'

import {
  TableContainer as ElementalTable,
  Tbody as ElementalTbody,
  fontSizes,
  fontWeights,
  spacing,
  styles,
} from '@fortum/elemental-ui'

export const TableContainer = styled(ElementalTable)`
  table {
    width: 100%;
    border-collapse: separate;
    table-layout: fixed;
    font-size: ${fontSizes.s.default};
    ${styles.breakpoint.s} {
      table-layout: auto;
    }
    ${styles.breakpoint.l} {
      font-size: ${fontSizes.m.default};
    }
  }
  &.multiColumn {
    padding-right: ${spacing.xxs};
    overflow-x: scroll;
    > table {
      table-layout: auto;
      overflow-x: scroll;
      ${styles.breakpoint.s} {
        overflow-x: hidden;
      }
    }
    ${styles.breakpoint.s} {
      overflow-y: scroll;
    }
  }
  & {
    overflow-x: hidden;
    .extraDesktopColumn {
      width: 0;
      padding: 0;
      ${styles.breakpoint.s} {
        width: 100%;
        padding: initial;
      }
    }
    th:not(:last-child) {
      ${styles.breakpoint.s} {
        width: fit-content;
      }
    }
    th {
      border-bottom: 2px solid ${({ theme }) => theme.colors.fogGrey}!important;
      background-color: ${({ theme }) => theme.colors.backgroundPrimary};
      text-align: center;
      text-transform: capitalize;
      position: sticky;
      top: 0;
      z-index: 1;
      overflow-wrap: normal;
      &:nth-last-child(n + 3) {
        border-right: 2px solid ${({ theme }) => theme.colors.fogGrey};
      }
    }
    td {
      white-space: nowrap;
      &:nth-last-child(n + 3) {
        border-right: 2px solid ${({ theme }) => theme.colors.fogGrey};
      }
    }
    th,
    td {
      padding: ${spacing.xxxs};
      height: 40px !important;
      ${styles.breakpoint.s} {
        padding: ${spacing.xxxs} ${spacing.xxs};
      }
    }
  }
`

export const Tbody = styled(ElementalTbody)`
  & {
    tr:nth-child(even) {
      background-color: ${({ theme }) => theme.colors.lightGrey};
    }
    td {
      background-color: transparent !important;
    }
  }
` as typeof ElementalTbody

export const tfoot = styled.tfoot`
  & {
    border-top: 2px solid ${({ theme }) => theme.colors.fogGrey}!important;
    font-weight: ${fontWeights.medium};

    td {
      position: sticky;
      bottom: 0;
      border-top: 2px solid ${({ theme }) => theme.colors.fogGrey}!important;
      border-bottom: 2px solid ${({ theme }) => theme.colors.snowWhite};
      background-color: ${({ theme }) => theme.colors.backgroundPrimary};
    }

    td:nth-last-child(n + 3) {
      border-right: 2px solid ${({ theme }) => theme.colors.fogGrey}!important;
    }
  }
`
