import type React from 'react'

import { Box, Loader } from '@fortum/elemental-ui'

import { ServerSideDefaultWrapper } from '@/shared/components/ServerSideDefaultWrapper'
import Chart from '@/shared/components/SpotPrices/Graph/Chart'
import { useTheme } from '@/shared/hooks/useTheme'

import { useYearlySpotConfig } from './YearlyConfig'
import type { SpotEntry } from '../commons'

type SpotGraphProps = {
  data: SpotEntry[]
}

export const YearlyGraph: React.FC<SpotGraphProps> = ({ data }) => {
  const config = useYearlySpotConfig(data)
  const { colors } = useTheme()

  return (
    <ServerSideDefaultWrapper
      serverSidePlaceholder={
        <Box
          height={{ default: '257px', m: '268px', l: '264px' }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Loader color={colors.oceanGreen} />
        </Box>
      }
    >
      <Chart data-testid="spot-price-chart" config={config} />
    </ServerSideDefaultWrapper>
  )
}
