import deepmerge from 'deepmerge'
import { produce } from 'immer'
import { DateTime } from 'luxon'
import { create } from 'zustand'

import type { TabOption } from '@/logged-in/components/WidgetTabSelector/types'
import type { DataResolution, PriceAreaCode } from '@/shared/graphql/schema/commonBackend/graphql'

import type { BottomTabOptionIds, DateResolution, KPI, SpotEntry, TopTabOptionIds } from './commons'

export type KPIs = {
  current: KPI
  lowest: KPI
  highest: KPI
}

export type SpotPriceComponentLayout = 'compact' | 'wide'

export type StateValues = {
  activeDate: DateTime
  KPIs: Partial<KPIs>
  bottomTabs: TabOption<BottomTabOptionIds>[]
  topTabs: TabOption<TopTabOptionIds>[]
  averagePrice: {
    value: string
    unit: string
  }
  mappedData: SpotEntry[]
  priceUnit: string | undefined
  priceArea: PriceAreaCode
  layout: SpotPriceComponentLayout
  dataResolution: DataResolution
  dateResolution: DateResolution
}

type StateModifiers = {
  setActiveDate: (newDate: DateTime) => void
  updateKPIs: (KPIs: Record<keyof KPIs, Partial<KPI>>) => void
  setBottomTabActive: (id: string) => void
  setTopTabActive: (id: string) => void
  setAveragePrice: (value: string, unit: string) => void
  setMappedData: (data: SpotEntry[]) => void
  setPriceUnit: (priceUnit: string) => void
  setPriceArea: (priceArea: PriceAreaCode) => void
  setResolution: (dataResolution: DataResolution, dateResolution: DateResolution) => void
}

export type State = StateValues & StateModifiers

export type SpotPricesStore = ReturnType<typeof createSpotPricesStore>

export const defaultValues: StateValues = {
  activeDate: DateTime.now(),
  KPIs: {
    current: {
      title: 'currentPrice',
      value: '51,12',
      rawValue: 51.12,
      unit: 'öre/kWh',
      subtitle: '10:00 - 11:00',
      color: 'positive',
    },
    highest: {
      title: 'highestPrice',
      value: '',
      rawValue: 0,
      unit: 'öre/kWh',
      subtitle: [],
      color: 'negative',
      direction: 'up',
    },
    lowest: {
      title: 'lowestPrice',
      value: '',
      rawValue: 0,
      unit: 'öre/kWh',
      subtitle: [],
      color: 'positive',
      direction: 'down',
    },
  },
  bottomTabs: [
    {
      id: 'graph',
      active: true,
    },
    {
      id: 'table',
    },
  ],
  topTabs: [{ id: 'daily', active: true }, { id: 'yearly' }],
  averagePrice: {
    value: '',
    unit: 'öre/kWh',
  },
  mappedData: [],
  priceUnit: undefined,
  priceArea: 'SE1',
  layout: 'wide',
  dataResolution: 'HOUR',
  dateResolution: 'Day',
}

const overwriteMerge = (_: string[], sourceArray: string[]): string[] => sourceArray

export const createSpotPricesStore = (
  initialValues?: (defaultValues: StateValues) => Partial<StateValues>,
) =>
  create<State>((set) => ({
    ...defaultValues,
    setActiveDate: (newDate) => {
      set(() => ({
        activeDate: newDate,
      }))
    },
    updateKPIs: (update) =>
      set(
        produce((state: State) => {
          state.KPIs = deepmerge(state.KPIs, update, {
            arrayMerge: overwriteMerge,
          })
        }),
      ),
    setBottomTabActive: (id) => {
      set((state) => ({
        bottomTabs: state.bottomTabs.map((tab) => ({
          ...tab,
          active: id === tab.id,
        })),
      }))
    },
    setTopTabActive: (id) => {
      set((state) => ({
        topTabs: state.topTabs.map((tab) => ({
          ...tab,
          active: id === tab.id,
        })),
      }))
    },
    setAveragePrice: (value, unit) => {
      set(() => ({
        averagePrice: {
          value: value,
          unit: unit,
        },
      }))
    },
    setMappedData: (mappedData) => {
      set(() => ({
        mappedData,
      }))
    },
    setPriceUnit: (priceUnit) => {
      set(() => ({ priceUnit }))
    },
    setPriceArea: (priceArea) => {
      set(() => ({ priceArea }))
    },
    setResolution: (dataResolution, dateResolution) => {
      set((state) => {
        state.dataResolution = dataResolution
        state.dateResolution = dateResolution
        return state
      })
    },
    ...initialValues?.(defaultValues),
  }))
