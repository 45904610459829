import styled from 'styled-components'

import { DateBrowser as ElementalDateBrowser } from '@fortum/elemental-ui'

export const DateBrowser = styled(ElementalDateBrowser)`
  button {
    height: 40px;
    font-size: 1rem;
  }
`
