import { DateTime } from 'luxon'

import { formatTimeSpan, setTZAndLocale } from '@/logged-in/utils/commonUtils'
import type { DateResolution, SpotEntry } from '@/shared/components/SpotPrices/commons'
import {
  formatMoney,
  getPositiveNegativeColorFromPrice,
} from '@/shared/components/SpotPrices/commons'
import { isNotNullOrUndefined } from '@/shared/utils/isNotNullOrUndefined'

export const calculateStats = (
  data: SpotEntry[],
  timeZone: string,
  locale: string,
  dateResolution: DateResolution,
) => {
  const prices = data.map((entry) => entry.price).filter(isNotNullOrUndefined)
  const averagePrice = data.reduce((acc, entry) => acc + (entry.price ?? 0), 0) / data.length

  const hasPrices = prices.length > 0
  const lowestPrice = hasPrices ? Math.min(...prices) : undefined
  const highestPrice = hasPrices ? Math.max(...prices) : undefined

  const current = data.find(
    (entry) =>
      DateTime.fromJSDate(new Date(entry.time)).setZone(timeZone).hour ===
      DateTime.now().setZone(timeZone).hour,
  )

  const lowestEntries = data
    .filter((entry) => entry.price === lowestPrice)
    .map((entry) => setTZAndLocale(entry.time, timeZone, locale))

  const highestEntries = data
    .filter((entry) => entry.price === highestPrice)
    .map((entry) => setTZAndLocale(entry.time, timeZone, locale))

  const getSubtitle = (type: 'lowest' | 'highest') => {
    if (!hasPrices) {
      return ''
    }

    switch (dateResolution) {
      case 'Day':
        return (type === 'lowest' ? lowestEntries : highestEntries).map((entry) =>
          formatTimeSpan(entry),
        )
      case 'Year':
        return getMonthFromEntries(type === 'lowest' ? lowestEntries : highestEntries)
      default:
        return ''
    }
  }

  const getMonthFromEntries = (entries: DateTime[]) => {
    if (entries.length > 0) {
      return entries[0]
        .setLocale(locale)
        .toFormat('MMMM')
        .replace(/^./, (str) => str.toUpperCase())
    }
    return ''
  }

  return {
    current: {
      value: formatMoney(current?.price),
      rawValue: current?.price,
      color: getPositiveNegativeColorFromPrice(current?.price, averagePrice),
      subtitle:
        current?.price != null
          ? formatTimeSpan(setTZAndLocale(current.time, timeZone, locale))
          : '',
    },
    lowest: {
      value: formatMoney(lowestPrice),
      rawValue: lowestPrice,
      subtitle: getSubtitle('lowest'),
    },
    highest: {
      value: formatMoney(highestPrice),
      rawValue: highestPrice,
      subtitle: getSubtitle('highest'),
    },
    average: formatMoney(averagePrice),
  }
}
