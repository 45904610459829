import { DateTime } from 'luxon'
import { useTranslations } from 'next-intl'
import type React from 'react'

import { Box, IconChevronLeft, IconChevronRight, fontSizes } from '@fortum/elemental-ui'

import { getTodayTomorrowBackFromActiveDate } from '@/shared/components/SpotPrices/commons'
import type { TodayTomorrowBack, TodayTomorrowPrice } from '@/shared/components/SpotPrices/commons'
import { sendGTMEvent } from '@/shared/utils/sendGTMEvent'
import { getTimeZoneForPriceAreaCode } from '@/shared/utils/timezone'

import { CustomButtonPill } from './styled'
import { useStore } from '../StoreProvider'

export const settingsByMode = {
  Today: {
    label: 'tomorrow',
    leftIcon: undefined,
    rightIcon: <IconChevronRight size="1.5rem" />,
    justifyContent: 'flex-end',
    nextDate: 1,
    priceLabel: 'todaysPrice',
  },
  Tomorrow: {
    label: 'today',
    leftIcon: <IconChevronLeft size="1.5rem" />,
    rightIcon: undefined,
    justifyContent: undefined,
    nextDate: 0,
    priceLabel: 'tomorrowsPrice',
  },
  Back: {
    label: 'back',
    leftIcon: undefined,
    rightIcon: <IconChevronRight size="1.5rem" />,
    justifyContent: 'flex-end',
    nextDate: 0,
    priceLabel: undefined,
  },
} satisfies Record<
  TodayTomorrowBack,
  {
    label: Lowercase<TodayTomorrowBack>
    priceLabel: TodayTomorrowPrice
    [key: string]: unknown
  }
>

type TomorrowTodaySwitcherProps = {
  disabled: boolean
}

export const TomorrowTodaySwitcher: React.FC<TomorrowTodaySwitcherProps> = ({ disabled }) => {
  const activeDate = useStore((s) => s.activeDate)
  const setActiveDate = useStore((s) => s.setActiveDate)
  const t = useTranslations('spotPrices')
  const priceArea = useStore((s) => s.priceArea)
  const mode = getTodayTomorrowBackFromActiveDate(activeDate, priceArea)
  const settings = settingsByMode[mode]
  const { leftIcon, rightIcon, nextDate, justifyContent, label } = settings

  const onClick = () => {
    if (disabled) {
      return
    }
    setActiveDate(
      DateTime.now().setZone(getTimeZoneForPriceAreaCode(priceArea)).plus({ day: nextDate }),
    )
    sendGTMEvent({
      event: 'graph_interaction',
      graph_title: 'Spot price',
      click_element: 'tomorrow_today_switcher',
      click_text: label,
    })
  }

  return (
    <Box display="flex" justifyContent={justifyContent}>
      <CustomButtonPill
        data-testid="day-switcher"
        size="s"
        status="plain"
        leftIcon={leftIcon}
        rightIcon={rightIcon}
        variant="condensed"
        onClick={onClick}
        disabled={disabled}
        fontSize={fontSizes.s}
      >
        {t(label)}
      </CustomButtonPill>
    </Box>
  )
}
