import type React from 'react'
import { flushSync } from 'react-dom'
import { createRoot } from 'react-dom/client'

export const simpleRenderToString = (element: React.ReactElement) => {
  const container = document.createElement('div')
  const root = createRoot(container)
  // TODO replace flushSync once new API is available from react-dom (replacement for 'renderToString')
  flushSync(() => {
    root.render(element)
  })

  return container.innerHTML
}

export const removeComponentsFromString = (text: string, components: string[]) => {
  const regexString = components.map((component) => `${component}="[^"]+"`).join('|')
  const regex = new RegExp(regexString, 'g')
  return text.replace(regex, '')
}
