import type Highcharts from 'highcharts'
import { DateTime } from 'luxon'

import type { ElementalColors } from '@/shared/components/SpotPrices/commons'

import type { SpotGraphContextPoint } from './config'

const nonNullFilter = <T extends Array<unknown>>(a: T) =>
  a.filter((i) => i != null) as Array<NonNullable<T extends Array<infer S> ? S : never>>

/**
 * Indicators should be rendered differently depending on the screen size thus this type
 */
type RendererMode = 'mobile' | 'tablet'

/**
 * Renderer is used to render indicators on graph such as lowest price and current time.
 * Since this is not a React Component, colors need to be passed down from actual Component.
 * @param chart - graph instance
 * @param colors - colors from useTheme hook, need to be passed since it's not a React Component
 * @param mode - depending on the screen size the rendered items should look differently
 */
type Renderer = (
  chart: Highcharts.Chart,
  colors: ElementalColors,
  mode: RendererMode,
) => Highcharts.SVGElement[] | undefined

/**
 * TimeIndicatorRenderer is used to render the time indicator dot for today. It uses the price area timezone.
 */
type TimeIndicatorRenderer = (
  chart: Highcharts.Chart,
  colors: ElementalColors,
  mode: RendererMode,
  timeZone: string,
) => Highcharts.SVGElement[] | undefined

/**
 * SingularRenderer is used to render a single item, since lowest prices or highest prices can have multiple occurrences
 * @param chart - graph instance
 * @param point - target position of rendered item
 * @param color - color of item to be rendered
 * @param mode - depending on the screen size the rendered items should look differently
 */
type SingularRenderer = (
  chart: Highcharts.Chart,
  point: Highcharts.Point,
  color: string,
  mode: RendererMode,
) => Highcharts.SVGElement | undefined

const SIZES = {
  circleRadius: {
    mobile: 5,
    tablet: 8,
  },
  triangleWidth: {
    mobile: 12,
    tablet: 16,
  },
} satisfies Record<string, Record<RendererMode, unknown>>

export const renderTimeIndicator: TimeIndicatorRenderer = (chart, colors, mode, timeZone) => {
  const index = DateTime.now().setZone(timeZone).hour
  const point = chart.series[0]?.points[index]
  if (!point?.plotX || !point.plotY) {
    return
  }
  const positionX = point.plotX + chart.plotLeft
  const positionY = point.plotY + chart.plotTop
  const dot = chart.renderer
    .circle(positionX, positionY, SIZES.circleRadius[mode])
    .attr({
      fill: colors.textWarning,
      zIndex: 2,
    })
    .add()
  dot.toFront()
  return [dot]
}

export const getTriangle = (color: string, mode: RendererMode) =>
  `data:image/svg+xml,${encodeURIComponent(`<svg
    width="${SIZES.triangleWidth[mode]}"
    height="${SIZES.triangleWidth[mode]}"
    viewBox="0 0 12 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6 9.5L0.803848 0.5L11.1962 0.5L6 9.5Z" fill="${color}" />
  </svg>`)}`

/**
 * If point is in the same hour as current hour return some offset
 */
const getOffsetForPoint = (point: Highcharts.Point) => {
  const currentHour = DateTime.now().hour
  return point.index === currentHour ? 6 : 0
}

const renderLowestIndicator: SingularRenderer = (chart, point, color, mode) => {
  const customPoint = point as SpotGraphContextPoint
  if (!customPoint.plotX || !customPoint.plotY) {
    return
  }
  const positionX = customPoint.plotX + chart.plotLeft - SIZES.triangleWidth[mode] / 2 - 0.5
  const positionY = customPoint.plotY + chart.plotTop
  if (!customPoint.priceMissing) {
    return chart.renderer
      .image(getTriangle(color, mode), positionX, positionY)
      .attr({
        translateY: 2 + getOffsetForPoint(point),
        zIndex: 2,
      })
      .add()
  }
}

export const renderLowestIndicators: Renderer = (chart, colors, mode) => {
  const points = chart.series[0]?.points as SpotGraphContextPoint[]
  if (!points) {
    return
  }
  let lowest = points[0]
  for (const point of points) {
    if (point.y == null) {
      continue
    }
    if (point.y < (lowest.y ?? Number.MAX_VALUE) && !point.priceMissing) {
      lowest = point
    }
  }
  const allLowestPoints = points.filter((point) => point.y === lowest.y)
  const svgs = allLowestPoints.map((point) =>
    renderLowestIndicator(chart, point, colors.textSuccess, mode),
  )
  return nonNullFilter(svgs)
}

const renderHighestIndicator: SingularRenderer = (chart, point, color, mode) => {
  const customPoint = point as SpotGraphContextPoint
  if (!customPoint.plotX || !customPoint.plotY) {
    return
  }
  const triangleWidth = SIZES.triangleWidth[mode]
  const positionX = customPoint.plotX + chart.plotLeft - triangleWidth / 2 - 0.5
  const positionY = customPoint.plotY + chart.plotTop - triangleWidth
  if (!customPoint.priceMissing) {
    return chart.renderer
      .image(getTriangle(color, mode), positionX, positionY)
      .attr({
        rotation: 180,
        rotationOriginX: positionX + triangleWidth / 2,
        rotationOriginY: positionY + triangleWidth / 2,
        translateY: -2 - getOffsetForPoint(point),
        zIndex: 2,
      })
      .add()
  }
}

export const renderHighestIndicators: Renderer = (chart, colors, mode) => {
  const points = chart.series[0]?.points as SpotGraphContextPoint[]
  if (!points) {
    return
  }
  let highest = points[0]
  for (const point of points) {
    if (!point.y) {
      continue
    }
    if (point.y > (highest.y ?? Number.MIN_VALUE) && !point.priceMissing) {
      highest = point
    }
  }
  const allHighestPoints = points.filter((point) => point.y === highest.y)
  const svgs = allHighestPoints.map((point) =>
    renderHighestIndicator(chart, point, colors.textAlert, mode),
  )
  return nonNullFilter(svgs)
}
