import type React from 'react'

import { Box } from '@fortum/elemental-ui'

type AraiaLiveRegionProps = {
  children: React.ReactNode
}
/**
 * A component to show a live region for screen readers
 */
export const AriaLiveRegion = ({ children }: AraiaLiveRegionProps) => {
  return (
    <Box
      role="status"
      aria-live="assertive"
      style={{
        position: 'absolute',
        left: '-9999px',
        height: '1px',
        width: '1px',
        overflow: 'hidden',
      }}
    >
      {children}
    </Box>
  )
}
