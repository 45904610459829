import type { FC } from 'react'

import { Box, spacing } from '@fortum/elemental-ui'

import { KPI } from '@/shared/components/SpotPrices/KPIs/KPI'

import { useStore } from '../StoreProvider'

interface KPIProps {
  showCurrent?: boolean
}

export const KPIs: FC<KPIProps> = ({ showCurrent }) => {
  const { current, lowest, highest } = useStore((s) => s.KPIs)
  const layout = useStore((s) => s.layout)
  const isCompact = layout === 'compact'

  return (
    <Box
      display="flex"
      flexDirection={{ default: 'column', s: 'row-reverse' }}
      gap={{ default: spacing.xxs, s: undefined }}
      justifyContent={{ s: showCurrent ? 'space-between' : 'flex-end' }}
      alignItems={{ s: 'center' }}
      maxWidth={!isCompact ? { m: '609px' } : {}}
    >
      <Box display="flex" justifyContent="space-between" gap={{ s: spacing.xs }}>
        {lowest && <KPI {...lowest} />}
        {highest && <KPI {...highest} />}
      </Box>

      <Box height={{ default: '72px', s: 'auto' }}>
        {current && showCurrent ? (
          <KPI {...current} size="large" isTooltipContainer />
        ) : (
          <Box id="spotprice-tooltip-container" />
        )}
      </Box>
    </Box>
  )
}
