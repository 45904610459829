import type { PriceAreaCode } from '@/shared/graphql/schema/commonBackend/graphql'

export const getTimeZoneForPriceAreaCode = (priceAreaCode: PriceAreaCode): string => {
  switch (true) {
    case priceAreaCode.startsWith('FI'):
      return 'Europe/Helsinki'
    case priceAreaCode.startsWith('NO'):
      return 'Europe/Oslo'
    case priceAreaCode.startsWith('SE'):
      return 'Europe/Stockholm'
    default:
      return 'Europe/Stockholm'
  }
}
