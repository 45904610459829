import { useTranslations } from 'next-intl'
import type React from 'react'
import { useMemo } from 'react'

import type { BoxProps, TableColumnProps } from '@fortum/elemental-ui'
import {
  Box,
  ContentText,
  TableElement,
  Td,
  Th,
  Tr,
  fontSizes,
  spacing,
} from '@fortum/elemental-ui'

import type { SpotEntry } from '@/shared/components/SpotPrices/commons'
import { getTriangle } from '@/shared/components/SpotPrices/Graph/renderers'
import { useBreakpoints, useResponsiveValue } from '@/shared/hooks/useBreakpoints'
import { useTheme } from '@/shared/hooks/useTheme'
import { useLocale } from '@/shared/locale'
import { getTimeZoneForPriceAreaCode } from '@/shared/utils/timezone'

import { processTableRows } from './processTableRows'
import type { SpotPriceTableRow } from './processTableRows'
import * as $ from './styled'
import { useStore } from '../StoreProvider'

type SpotPriceIndicator = 'highest' | 'lowest' | 'current'
type SpotPriceIndicatorLabel = `${SpotPriceIndicator}Price`

const LABELS = {
  highest: 'highestPrice',
  lowest: 'lowestPrice',
  current: 'currentPrice',
} satisfies Record<SpotPriceIndicator, SpotPriceIndicatorLabel>

const PriceTableCell = ({ price, indicators }: SpotPriceTableRow): React.ReactNode => {
  const { colors } = useTheme()
  const { isMobile } = useBreakpoints()
  const t = useTranslations('spotPrices')

  const priceContent = (
    <ContentText
      data-test="table-price-content"
      fontSize={fontSizes.s}
      textAlign={{ default: 'right', s: 'left' }}
    >
      {price}
    </ContentText>
  )

  const renderIndicator = () => {
    switch (true) {
      case indicators.includes('lowest'):
        return (
          <img
            alt="lowest"
            src={getTriangle(colors.textSuccess, 'mobile')}
            data-testid="price-indicator-lowest"
          />
        )
      case indicators.includes('highest'):
        return (
          <$.RotatedImg
            alt="highest"
            src={getTriangle(colors.textAlert, 'mobile')}
            data-testid="price-indicator-highest"
          />
        )
      case indicators.includes('current'):
        return (
          <Box
            backgroundColor={colors.textPrimary}
            width="12px"
            height="12px"
            borderRadius="100%"
            data-testid="price-indicator-current"
          />
        )
      default:
        return <Box display={{ default: 'none', s: 'block' }} width="12px" height="12px" />
    }
  }

  const label = indicators.map((indicator) => t(LABELS[indicator])).join(', ')

  return (
    <Box
      display="grid"
      gridTemplateColumns="auto auto"
      gap={spacing.xxs}
      justifyContent={{ default: 'flex-end', s: 'flex-start' }}
      alignItems="center"
    >
      <Box display="flex" gap={spacing.xxxs} alignItems="center" width={{ s: '9rem' }}>
        {renderIndicator()}
        {!isMobile && priceContent}
      </Box>
      <Box
        display="flex"
        flexDirection={{ default: 'column', s: 'row' }}
        alignItems="start"
        gap={{ s: spacing.xxs }}
      >
        {isMobile && priceContent}
        <ContentText data-testid="lowest-price-content-text" fontSize={fontSizes.xs}>
          {label}
        </ContentText>
      </Box>
    </Box>
  )
}

const Row = (row: SpotPriceTableRow): React.ReactNode => {
  const priceColumnAlignment = useResponsiveValue({
    default: 'right',
    s: 'left',
  })
  const { colors } = useTheme()

  const isRowCurrent = row.indicators.includes('current')

  const styles = useMemo(() => {
    if (!isRowCurrent) {
      return {
        left: {},
        right: {},
      }
    }

    const borderDef = `2px solid ${colors.borderSuccess}`

    const commonStyles = {
      borderTop: borderDef,
      borderBottom: borderDef,
    }

    return {
      left: {
        ...commonStyles,
        borderLeft: borderDef,
      } satisfies BoxProps,
      center: {
        ...commonStyles,
        borderRight: {
          default: borderDef,
          s: 'unset',
        },
      },
      right: {
        ...commonStyles,
        borderRight: borderDef,
      },
    }
  }, [colors, row.indicators])

  return (
    <Tr
      data-testid="table-time-row"
      key={row.id}
      style={{
        backgroundColor: isRowCurrent ? colors.backgroundSuccess : undefined,
      }}
    >
      <Td
        data-testid="table-time-content"
        textAlign="left"
        fontSize={fontSizes.s}
        verticalAlign="middle"
        {...styles.left}
      >
        {row.timestamp}
      </Td>
      <Td textAlign={priceColumnAlignment} {...styles.center}>
        <PriceTableCell {...row} />
      </Td>
      <Td className="extraDesktopColumn" {...styles.right} />
    </Tr>
  )
}

type TableProps = {
  data: SpotEntry[]
}

export const Table: React.FC<TableProps> = ({ data }) => {
  const t = useTranslations('spotPrices')
  const averagePrice = useStore((s) => s.averagePrice)
  const highestPrice = useStore((s) => s.KPIs.highest)
  const lowestPrice = useStore((s) => s.KPIs.lowest)
  const locale = useLocale()
  const timeZone = getTimeZoneForPriceAreaCode(useStore((s) => s.priceArea))
  const dateResolution = useStore((s) => s.dateResolution)

  const rows = useMemo(
    () =>
      processTableRows(
        data,
        highestPrice?.rawValue,
        lowestPrice?.rawValue,
        timeZone,
        locale,
        dateResolution,
      ),
    [data, highestPrice?.rawValue, lowestPrice?.rawValue, timeZone, locale, dateResolution],
  )

  const columnsHeader: TableColumnProps<SpotPriceTableRow, unknown>[] = [
    {
      getCellData: (row) => row.timestamp,
      key: 'timestamp',
      name: t(dateResolution === 'Year' ? 'month' : 'hour'),
    },
    {
      getCellData: (row) => row.price,
      key: 'price',
      name: t('price'),
    },
  ]

  return (
    <$.TableContainer data-testid="spotprice-table" height={{ default: 'auto', s: '353px' }}>
      <TableElement data-testid="table-element">
        <thead>
          <$.StyledTr data-testid="table-header-row">
            {columnsHeader.map((column, index) => (
              <Th
                key={column.key}
                columnKey={column.key}
                className={index === 1 ? 'leftAligned' : ''}
              >
                {column.name}
              </Th>
            ))}
            <Th className="extraDesktopColumn" columnKey="hidden">
              &nbsp;
            </Th>
          </$.StyledTr>
        </thead>
        <$.Tbody data-testid="table-body">
          {rows.map((row) => (
            <Row {...row} key={row.id} />
          ))}
        </$.Tbody>
        <$.tfoot>
          <$.StyledTr>
            <Td textAlign="left">{t('average')}</Td>
            <Td textAlign="right" className="leftAligned">
              {averagePrice.value} {averagePrice.unit}
            </Td>
            <Td className="extraDesktopColumn" />
          </$.StyledTr>
        </$.tfoot>
      </TableElement>
    </$.TableContainer>
  )
}
