import { useEffect, useState } from 'react'
import type React from 'react'

type ServerSideDefaultWrapperProps = {
  serverSidePlaceholder: React.ReactNode
  children: React.ReactNode
}
export const ServerSideDefaultWrapper: React.FC<ServerSideDefaultWrapperProps> = ({
  serverSidePlaceholder,
  children,
}) => {
  const [isHydrated, setIsHydrated] = useState(false)

  useEffect(() => {
    setIsHydrated(true)
  }, [])

  if (!isHydrated) {
    return serverSidePlaceholder
  }

  return children
}
