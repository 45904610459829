import { useTranslations } from 'next-intl'
import type React from 'react'

import { ContentText, FontSize, spacing } from '@fortum/elemental-ui'

import { useStore } from '../StoreProvider'

export const AveragePrice: React.FC = () => {
  const price = useStore((s) => s.averagePrice)
  const t = useTranslations('spotPrices')

  return (
    <ContentText
      data-testid="daily-average-price"
      display="flex"
      gap={spacing.xxxxs}
      size={FontSize.s}
      height={{ default: '21px', m: '24px' }}
    >
      {`${t('dailyAveragePrice')} ${price.value} ${price.unit}`}
    </ContentText>
  )
}
