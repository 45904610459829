import { type Chart } from 'highcharts'
import type Highcharts from 'highcharts'
import cloneDeep from 'lodash.clonedeep'
import isEqual from 'lodash.isequal'
import { DateTime } from 'luxon'
import { useTranslations } from 'next-intl'
import { useMemo } from 'react'

import { colors } from '@fortum/elemental-ui'

import { useBreakpoints } from '@/shared/hooks/useBreakpoints'
import { useTheme } from '@/shared/hooks/useTheme'
import { useFormatter, useLocale } from '@/shared/locale'

import type { SpotEntry } from '../commons'
import { TooltipContainerId, getPositiveNegativeColorFromPrice } from '../commons'
import SpotTooltip, { getYearlyToolTipheading } from '../Graph/Tooltip'

export type SpotGraphContextPoint = Highcharts.Point & {
  y: number | undefined
  priceMissing: boolean
} & SpotEntry

export type SpotGraphSerie = Highcharts.Series & {
  zones: Highcharts.SeriesZonesOptionsObject[]
  isDirty: boolean
}

const TICK_AMOUNT = 5
const FONT_FAMILY = 'FortumSans,arial,sans-serif'

export const formatYearlyTimeSpan = (date: Date, locale: string) => {
  const luxonDate = DateTime.fromJSDate(date).setLocale(locale)

  const month = luxonDate.toLocaleString({ month: 'long' })
  return `${month}`
}

const handleScrollEvent = (chart: Chart) => {
  window.addEventListener('scroll', function handler() {
    if (chart.tooltip) {
      chart.tooltip.hide(0)
    } else {
      window.removeEventListener('scroll', handler)
    }
  })
}

let hideTooltipTimeoutId: number

const registerProxiesForChart = (chart: Highcharts.Chart, isMobile: boolean) => {
  // Listen to calls to native hide tooltip to draw short crosshair
  chart.tooltip.hide = new Proxy(chart.tooltip.hide, {
    apply(target, thisArg, argArray) {
      hideTooltipTimeoutId = window.setTimeout(() => {}, (chart.tooltip.options.hideDelay ?? 0) + 1)
      Reflect.apply(target, thisArg, argArray)
    },
  })

  // Listen to calls to native refresh tooltip to stop prevent short crosshair
  chart.tooltip.refresh = new Proxy(chart.tooltip.refresh, {
    apply(target, thisArg, argArray) {
      window.clearTimeout(hideTooltipTimeoutId)
      Reflect.apply(target, thisArg, argArray)
    },
  })

  if (!isMobile) {
    return
  }
}

const updateZones = (points: SpotGraphContextPoint[]) => {
  const newZones = []
  for (const point of points) {
    if (point.priceMissing) {
      newZones.push({
        value: point.index + 1,
        color: 'transparent',
      })
    } else {
      newZones.push({
        value: point.index + 1,
        color: colors.oceanGreen,
      })
    }
  }
  return newZones
}

export const useUpdateZones = (points: SpotGraphContextPoint[]) => {
  return useMemo(() => updateZones(points), [points])
}

let cacheZones: Highcharts.SeriesZonesOptionsObject[] = []
export const useYearlySpotConfig = (data: SpotEntry[]) => {
  const { isMobile, isTablet, isDesktop } = useBreakpoints()
  const { colors } = useTheme()
  const t = useTranslations('spotPrices')
  const { number } = useFormatter()
  const chartType = 'column'
  const locale = useLocale()

  const categories: string[] = useMemo(() => {
    return data.map((entry) => {
      const entryDate = DateTime.fromJSDate(new Date(entry.time))
      return entryDate
        .setLocale(locale)
        .toFormat('LLL')
        .slice(0, 3)
        .replaceAll('.', '')
        .replace(/^\w/, (c) => c.toUpperCase())
    })
  }, [data, locale])

  const chartHeight = (() => {
    if (isMobile) {
      return 257
    }
    if (isTablet) {
      return 268
    }
    if (isDesktop) {
      return 264
    }
  })()

  const spotGraphData = data.map((entry, index) => {
    const entryDataUpToIndex = data.slice(0, index + 1).filter((e) => e.price !== undefined)
    const lastEntryWithData = entryDataUpToIndex.at(-1) ?? { price: 0 }

    return {
      y: entry.price ?? lastEntryWithData.price,
      priceMissing: entry.price === undefined,
      ...entry,
    }
  })

  const averagePrice = data.reduce((acc, entry) => (entry.price ?? 0) + acc, 0) / data.length
  const isEmpty = !data.some((entry) => entry.price)

  const config: Highcharts.Options = {
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    boost: {
      debug: {
        timeSeriesProcessing: true,
        timeRendering: true,
      },
    },
    chart: {
      marginTop: 40,
      marginBottom: 32,
      backgroundColor: `transparent`,
      spacingLeft: -10,
      spacingRight: 0,
      height: `${chartHeight}px`,
      events: {
        load() {
          const points = this.series[0].points as SpotGraphContextPoint[]
          const newZones = updateZones(points)
          this.series[0].update({
            type: chartType,
            zones: newZones,
          })
        },
        render() {
          // eslint-disable-next-line @typescript-eslint/no-this-alias, unicorn/no-this-assignment
          const chart = this

          registerProxiesForChart(chart, isMobile)

          if (chart.series.length > 0 && chart.series[0].graph) {
            chart.series[0].graph.destroy()
          }

          const points = this.series[0].points as SpotGraphContextPoint[]
          const newZones = updateZones(points)

          if (!isEqual(newZones, cacheZones)) {
            cacheZones = cloneDeep(newZones)

            this.series[0].update({
              type: chartType,
              zones: newZones,
            })
          }
        },
      },
    },
    title: {
      text: undefined,
    },
    series: [
      {
        type: chartType,
        name: 'spot',
        zoneAxis: 'x',
        zones: cacheZones,

        accessibility: {
          point: {
            descriptionFormatter: (point) => {
              const contextPoint = point as SpotGraphContextPoint
              return `${getYearlyToolTipheading(new Date(contextPoint.time), locale)}, ${
                contextPoint.y || 0
              } ${contextPoint.unit}`
            },
          },
        },
        animation: false,
        color: colors.oceanGreen,
        data: spotGraphData,

        states: {
          hover: {
            lineWidth: 3,
          },
        },
        yAxis: 0,
      },
    ],
    plotOptions: {
      series: {
        marker: {
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
    },
    yAxis: {
      tickAmount: TICK_AMOUNT,
      labels: {
        style: {
          fontSize: isMobile ? `12px` : `14px`,
          fontFamily: FONT_FAMILY,
          color: colors.text,
        },
        y: 3,
      },
      min: 0,
      max: isEmpty ? 100 : undefined,
      title: {
        style: {
          fontSize: isMobile ? `12px` : `14px`,
          fontFamily: FONT_FAMILY,
          color: colors.text,
        },
        text: 'öre/kWh',
        offset: 0,
        y: -22,
        x: 13,
        rotation: 0,
        align: 'high',
      },
    },

    xAxis: {
      crosshair: isMobile
        ? {
            color: 'transparent',
            width: 0.1,
          }
        : {
            color: colors.text,
            dashStyle: 'Dash',
            width: 1,
          },
      offset: -10,
      lineWidth: 0,
      title: {
        text: null,
      },
      labels: {
        step: isMobile ? 2 : 1,
        autoRotation: [0],
        style: {
          fontSize: isMobile ? `12px` : `14px`,
          fontFamily: FONT_FAMILY,
          color: colors.text,
        },
      },
      categories,
      tickWidth: 0,
    },
    tooltip: {
      animation: false,
      shadow: false,
      borderWidth: 0,
      borderRadius: 0,
      padding: 0,
      shared: true,
      shape: 'rect',
      backgroundColor: undefined,
      hideDelay: 100,
      formatter: function () {
        const point = this.point as SpotGraphContextPoint
        const date = new Date(point.time)
        const heading = getYearlyToolTipheading(date, locale)
        const labels = {
          dataNotAvailable: t('dataNotAvailable'),
        }
        return SpotTooltip({
          heading,
          price: point.price
            ? number(point.price, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            : '',
          unit: point.unit,
          color: getPositiveNegativeColorFromPrice(point.price, averagePrice),
          colors,
          rendererMode: isMobile ? 'default' : 'tablet',
          labels,
        })
      },
      useHTML: true,
      outside: true,
      positioner: isMobile
        ? function (_, height) {
            const thiz = this as unknown as Highcharts.Tooltip
            const chart = thiz.chart
            const chartPosition = chart.pointer.getChartPosition()
            const tooltipPosition = document
              .querySelector(`#${TooltipContainerId}`)
              ?.getBoundingClientRect()

            handleScrollEvent(chart)

            return {
              x: -chartPosition.left + (tooltipPosition?.left ?? 0),
              y: chart.plotTop - height - 65,
            }
          }
        : undefined,
    },
  }

  return config
}
