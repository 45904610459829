import { DateTime } from 'luxon'

import { setTZAndLocale } from '@/logged-in/utils/commonUtils'
import type { DateResolution, SpotEntry } from '@/shared/components/SpotPrices/commons'
import { formatMoney } from '@/shared/components/SpotPrices/commons'

export type SpotPriceIndicator = 'highest' | 'lowest' | 'current'

export type SpotPriceTableRow = {
  timestamp: string
  price: string
  id: number
  indicators: SpotPriceIndicator[]
}

const memoizedFormatTimestamp = (() => {
  const cache: Record<string, string> = {}

  return (locale: string, timeZone: string, date: DateTime, resolution: DateResolution): string => {
    const cacheKey = `${date.toISO()}${locale}${timeZone}${resolution}`
    if (cache[cacheKey]) {
      return cache[cacheKey]
    }

    const localDate = setTZAndLocale(date, timeZone, locale)

    const formattedTimestamp =
      resolution === 'Year'
        ? date
            .setLocale(locale)
            .toFormat('LLL')
            .slice(0, 3)
            .replace(/^./, (str) => str.toUpperCase())
        : localDate.toFormat('HH.mm')

    cache[cacheKey] = formattedTimestamp
    return formattedTimestamp
  }
})()

export const processTableRows = (
  data: SpotEntry[] | undefined,
  highestPrice: number | undefined,
  lowestPrice: number | undefined,
  timeZone: string,
  locale: string,
  dateResolution: DateResolution,
) => {
  return (data?.map((entry, id) => {
    const dateWithoutMinutes = DateTime.fromJSDate(new Date(entry.time)).set({ minute: 0 })

    const indicators = new Array<SpotPriceIndicator>()
    if (dateWithoutMinutes.setZone(timeZone).hasSame(DateTime.now().setZone(timeZone), 'hour')) {
      indicators.push('current')
    }
    if (highestPrice != null && entry.price === highestPrice) {
      indicators.push('highest')
    }
    if (lowestPrice != null && entry.price === lowestPrice) {
      indicators.push('lowest')
    }

    const timestamp =
      entry.price !== undefined
        ? memoizedFormatTimestamp(locale, timeZone, dateWithoutMinutes, dateResolution)
        : '--'
    const price = entry.price !== undefined ? `${formatMoney(entry.price)} ${entry.unit}` : '--'

    return {
      timestamp,
      price,
      id,
      indicators,
    }
  }) ?? []) satisfies SpotPriceTableRow[]
}
