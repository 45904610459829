import { useTranslations } from 'next-intl'
import type React from 'react'

import {
  Box,
  Button,
  ContentText,
  IconError,
  IconInfoLargeFilled,
  IconReload,
  Loader,
  spacing,
} from '@fortum/elemental-ui'

import { Icon } from '@/shared/components/Icon'
import { useTheme } from '@/shared/hooks/useTheme'

type GraphStatesProps = {
  state: 'loading' | 'error' | 'no-data' | 'no-tomorrow-data'
  onRefresh?: VoidFunction
}

export const GraphStates: React.FC<GraphStatesProps> = ({ state, onRefresh }) => {
  const theme = useTheme()
  const t = useTranslations('graphStates')

  switch (state) {
    //TODO: render 'no-data' case when getting latestMeasurementDate data from the API
    case 'no-data':
      return (
        <Box height="100%" display="flex" justifyContent="center" alignItems="center">
          <Box data-testid="consumption-data-not-available-message" display="flex">
            <Icon
              icon={IconInfoLargeFilled}
              color={theme.colors.cushyBlue}
              size={spacing.s}
              marginRight={spacing.xxs}
            />
            <ContentText size="m">{t('noData')}</ContentText>
          </Box>
        </Box>
      )
    case 'error':
      return (
        <Box height="100%" display="flex" justifyContent="center" alignItems="center">
          <Box data-testid="consumption-error-message" display="flex">
            <Icon
              icon={IconError}
              color={theme.colors.hazeRedOrange}
              size={spacing.s}
              marginRight={spacing.xxs}
            />
            <ContentText size="m">{t('error')}</ContentText>
          </Box>
        </Box>
      )
    case 'loading':
      return (
        <Box height="100%" display="flex" justifyContent="center" alignItems="center">
          <Box data-testid="consumption-loading-spinner" display="flex">
            <Loader color={theme.colors.oceanGreen} />
          </Box>
        </Box>
      )
    case 'no-tomorrow-data':
      return (
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap={spacing.xxs}
          paddingHorizontal={spacing.xxxs}
          data-testid="no-tomorrow-data-available-message"
        >
          <ContentText textAlign="center">{t('noTomorrowData')}</ContentText>
          <Button variant="condensed" onClick={onRefresh} status="plain" rightIcon={IconReload}>
            {t('refresh')}
          </Button>
        </Box>
      )
  }
}
