import { useTranslations } from 'next-intl'
import type React from 'react'

import type { ContentSwitcherItem } from '@fortum/elemental-ui'

import { WidgetTabSelector } from '@/logged-in/components/WidgetTabSelector'
import { sendGTMEvent } from '@/shared/utils/sendGTMEvent'

import { useStore } from '../StoreProvider'

export const TabSelector: React.FC = () => {
  const t = useTranslations('spotPrices')
  const bottomTabs = useStore((s) => s.bottomTabs)
  const setBottomTabActive = useStore((s) => s.setBottomTabActive)

  const setActiveBottomTab = (tabId: string) => {
    setBottomTabActive(tabId)

    if (tabId === 'graph') {
      scrollToGraphTop()
    }

    sendGTMEvent({
      event: 'graph_interaction',
      graph_title: 'Spot Price',
      click_element: 'content_switcher',
      click_text: tabId,
    })
  }

  const activeBottomTab = bottomTabs.find((tab) => tab.active)

  const items = bottomTabs.map((tab) => ({
    ...tab,
    name: t(tab.id),
  })) satisfies ContentSwitcherItem<string>[]

  return (
    <WidgetTabSelector
      items={items}
      active={activeBottomTab?.id}
      onTabChange={setActiveBottomTab}
    />
  )
}

const scrollToGraphTop = () => {
  const element = document.getElementById('spot-price-graph-container')
  if (element) {
    const headerOffset = 80
    const elementPosition = element.getBoundingClientRect().top
    const offsetPosition = elementPosition + window.scrollY - headerOffset

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    })
  }
}
