'use client'

import { Hydrate } from '@tanstack/react-query'
import type { DehydratedState } from '@tanstack/react-query'
import type React from 'react'

import { trpc } from '@/shared/trpc/react'

export const TrpcHydrate = ({
  children,
  state,
}: {
  children: React.ReactNode
  state: DehydratedState | undefined
}) => {
  const context = trpc.useUtils()
  return <Hydrate state={trpc.useDehydratedState(context.client, state)}>{children}</Hydrate>
}
