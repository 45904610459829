import { DateTime } from 'luxon'

import type { PriceAreaCode, SpotPriceSeries } from '@/shared/graphql/schema/commonBackend/graphql'
import type { useTheme } from '@/shared/hooks/useTheme'
import { isToday, isTomorrow } from '@/shared/utils/dates'

export const TODAY_TOMORROW_BACK = ['Today', 'Tomorrow', 'Back'] as const
export type TodayTomorrowBack = (typeof TODAY_TOMORROW_BACK)[number]
const TODAY_TOMORROW_PRICE = ['todaysPrice', 'tomorrowsPrice', undefined] as const
export type TodayTomorrowPrice = (typeof TODAY_TOMORROW_PRICE)[number]

const GRANULARITY_OPTIONS = ['Day', 'Month', 'Year'] as const
export type GranularityOption = (typeof GRANULARITY_OPTIONS)[number]

export const getTodayTomorrowBackFromActiveDate = (
  date: DateTime,
  priceArea: PriceAreaCode,
): TodayTomorrowBack => {
  if (isToday(date, priceArea)) {
    return 'Today'
  }
  if (isTomorrow(date, priceArea)) {
    return 'Tomorrow'
  }
  return 'Back'
}

export type PositiveNegativeColor = 'positive' | 'negative'

export type KPI = {
  title: 'currentPrice' | 'highestPrice' | 'lowestPrice'
  subtitle: string | string[]
  value: string
  rawValue: number
  unit?: string
  direction?: 'up' | 'down'
  color?: PositiveNegativeColor
}

export type TopTabOptionIds = 'daily' | 'yearly'
export type BottomTabOptionIds = 'graph' | 'table'
export type DateResolution = 'Day' | 'Month' | 'Year'

export type SpotEntry = {
  price?: number
  time: string
  unit: string
}

export type Version = 'first' | 'second'

export const getHexFromPositiveNegativeColor = (
  color: PositiveNegativeColor | undefined,
  colors: ElementalColors,
) => {
  switch (color) {
    case 'negative':
      return colors.textAlert
    case 'positive':
      return colors.textSuccess
    default:
      return
  }
}

export const getPositiveNegativeColorFromPrice = (
  price: number | undefined,
  avg: number,
): PositiveNegativeColor => ((price ?? 0) > avg ? 'negative' : 'positive')

export const formatMoney = (money: number | undefined) =>
  money != null && !Number.isNaN(money)
    ? new Intl.NumberFormat('sv-SE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(money)
    : '-'

export type ElementalColors = ReturnType<typeof useTheme>['colors']

export const TooltipContainerId = 'spotprice-tooltip-container'

const generateEmpty = (baseDate: Date, priceUnit?: string) => {
  const date = DateTime.fromJSDate(baseDate).set({ hour: 0 })
  return Object.fromEntries(
    new Array(24).fill(void 0).map((_, index) => [
      index,
      {
        time: date.set({ hour: index }).toJSDate().toISOString(),
        unit: priceUnit || 'kWh',
      } satisfies SpotEntry,
    ]),
  ) as Record<number, SpotEntry>
}

export const fillInEmptySpotPriceEntries = (
  data: SpotEntry[],
  activeDate: Date,
  priceUnit: string,
) => {
  if (24 === data.length) {
    return data
  }
  if (data.length > 24) {
    // eslint-disable-next-line no-console
    console.warn('spotprice data was longer than 24!')
  }
  const blanks = generateEmpty(activeDate, priceUnit)
  for (const entry of data) {
    const key = DateTime.fromJSDate(new Date(entry.time)).hour
    blanks[key] = entry
  }
  return Object.values(blanks)
}

export const mapSpotPriceSeries = (
  spotPriceSeries: SpotPriceSeries[] | undefined,
  priceUnit: string | undefined,
): SpotEntry[] =>
  (spotPriceSeries ?? []).map((entry) => ({
    price: entry.spotPrice?.value,
    time: entry.atUTC,
    unit: priceUnit ?? '',
  }))

export const CrosshairId = 'dashed-line'
