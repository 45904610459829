'use client'

import { useTranslations } from 'next-intl'
import type React from 'react'

import { WidgetHeading } from '@/logged-in/components/WidgetHeading'

import { useStore } from '../StoreProvider'

/**
 * Heading component for SpotGraph component - displays title and info icon with popover
 */
export const Heading: React.FC = () => {
  const priceArea = useStore((s) => s.priceArea)

  const t = useTranslations('spotPrices')

  return (
    <WidgetHeading
      data-testid="spot-price-date-heading"
      heading={`${t('heading')} ${priceArea}`}
      popover={t('headingPopover', { priceArea })}
    />
  )
}
