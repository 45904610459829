import { useTranslations } from 'next-intl'
import type React from 'react'

import type { ContentSwitcherItem } from '@fortum/elemental-ui'

import { WidgetTabSelector } from '@/logged-in/components/WidgetTabSelector'

import { useStore } from '../StoreProvider'

export const TopTabSelector: React.FC = () => {
  const t = useTranslations('spotPrices')
  const topTabs = useStore((s) => s.topTabs)
  const setTopTabActive = useStore((s) => s.setTopTabActive)

  // TODO: Add GTMEvent --> same as bottomTabs
  const setActiveTopTab = (tabId: string) => {
    if (tabId === activeTopTab?.id) {
      return
    }

    setTopTabActive(tabId)
  }

  const activeTopTab = topTabs.find((tab) => tab.active)
  const items = topTabs.map((tab) => ({
    ...tab,
    name: t(tab.id),
  })) satisfies ContentSwitcherItem<string>[]

  return <WidgetTabSelector items={items} active={activeTopTab?.id} onTabChange={setActiveTopTab} />
}
