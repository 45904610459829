import { DateTime } from 'luxon'

import { Box, ContentText, FontSize, fontWeights, spacing } from '@fortum/elemental-ui'

import { formatTimeSpan, setTZAndLocale } from '@/logged-in/utils/commonUtils'
import { removeComponentsFromString, simpleRenderToString } from '@/shared/utils/renderToString'

import type { ElementalColors, PositiveNegativeColor } from '../commons'
import { TooltipContainerId, getHexFromPositiveNegativeColor } from '../commons'
import { formatYearlyTimeSpan } from '../YearlyGraph/YearlyConfig'

/**
 * Depending on screen size Tooltip has slightly different look
 */
type RendererMode = 'default' | 'tablet'

type SpotPriceToolTipLabel = 'dataNotAvailable'

export type SpotTooltipProps = {
  heading: string
  price: string
  unit: string
  color: PositiveNegativeColor
  colors: ElementalColors
  rendererMode: RendererMode
  labels: Record<SpotPriceToolTipLabel, string>
}

const safelyWrapInPx = (value: number | undefined) => (value ? `${value}px` : undefined)

const SIZES = {
  valueFontSize: {
    default: '1.625rem',
    tablet: '1.4375rem',
  },
  unitLineHeight: {
    default: 2.1,
    tablet: 1.8,
  },
} satisfies Record<string, Record<RendererMode, unknown>>

const SpotTooltip = ({
  heading,
  price,
  unit,
  color,
  colors,
  rendererMode,
  labels,
}: SpotTooltipProps): string => {
  const mappedColor = getHexFromPositiveNegativeColor(color, colors)

  const tooltipContainer = document.querySelector(`#${TooltipContainerId}`)

  const width = safelyWrapInPx(
    rendererMode === 'default' ? tooltipContainer?.clientWidth : undefined,
  )
  const height = safelyWrapInPx(
    rendererMode === 'default' ? tooltipContainer?.clientHeight : undefined,
  )

  return removeComponentsFromString(
    simpleRenderToString(
      <Box
        id="spotprice-tooltip-container"
        padding={spacing.xxxs}
        display="flex"
        flexDirection="column"
        borderRadius={spacing.xxxxs}
        border={`1px solid ${colors.borderPrimary}`}
        backgroundColor={colors.background}
        width={width}
        height={height}
        justifyContent="center"
        style={{
          boxSizing: 'border-box',
        }}
      >
        <ContentText color={colors.textPrimary} style={{ textTransform: 'capitalize' }}>
          {heading}
        </ContentText>
        <Box display="flex" alignItems="flex-end" gap={spacing.xxxxs}>
          {price ? (
            <ContentText
              fontSize={SIZES.valueFontSize[rendererMode]}
              lineHeight={1.5}
              color={mappedColor}
              fontWeight={fontWeights.medium}
            >
              {price}
            </ContentText>
          ) : (
            <ContentText
              size={FontSize.s}
              fontWeight={fontWeights.regular}
              color={colors.textPrimary}
              mt={spacing.xxxs}
              mb={spacing.xxxs}
            >
              {labels.dataNotAvailable}
            </ContentText>
          )}
          <ContentText size={FontSize.s} lineHeight={SIZES.unitLineHeight[rendererMode]}>
            {price ? unit : ''}
          </ContentText>
        </Box>
      </Box>,
    ),
    ['data-test'],
  )
}

export default SpotTooltip

export const getTooltipHeading = (date: Date, timeZone: string, locale: string) => {
  const luxonDate = DateTime.fromJSDate(date)
  const localDate = setTZAndLocale(luxonDate, timeZone, locale)
  return formatTimeSpan(localDate)
}

export const getYearlyToolTipheading = (date: Date, locale: string) =>
  formatYearlyTimeSpan(date, locale)
