import { DateTime } from 'luxon'

import type { GranularityOption } from '@/logged-in/components/Energy/commons'
import { mockPriceArea } from '@/logged-in/mockData/consumptionGraph'
import { getTimeZoneForPriceAreaCode } from '@/shared/utils/timezone'

import type { PriceAreaCode } from '../graphql/schema/commonBackend/graphql'

export const getDateRange = (
  granularity: GranularityOption,
  date: DateTime | null,
  priceAreaCode: PriceAreaCode,
  resolution: 'date' | 'dateTime' = 'dateTime',
): [string, string] => {
  const timeZone = getTimeZoneForPriceAreaCode(priceAreaCode)
  const rangeDate = date ?? DateTime.now().setZone(timeZone)
  let start = rangeDate
  let end = rangeDate

  switch (granularity) {
    case 'Day':
      start = rangeDate.startOf('day')
      end = rangeDate.endOf('day')
      break
    case 'Month':
      start = rangeDate.startOf('month')
      end = rangeDate.endOf('month')
      break
    case 'Year':
      start = rangeDate.startOf('year')
      end = rangeDate.endOf('year')
      break
  }

  return resolution === 'date'
    ? [start.toISODate() ?? '', end.toISODate() ?? '']
    : [start.toUTC().toISO() ?? '', end.toUTC().toISO() ?? '']
}

export const isToday = (date: DateTime, priceArea: PriceAreaCode) =>
  date
    .setZone(getTimeZoneForPriceAreaCode(priceArea))
    .startOf('day')
    .equals(DateTime.now().setZone(getTimeZoneForPriceAreaCode(priceArea)).startOf('day'))

export const isTomorrow = (date: DateTime, priceArea: PriceAreaCode) =>
  isToday(date.minus({ day: 1 }), priceArea)

/**
    This function is used to get the date formatted date for the tests based on granularity.
    @param mockDate - The date to format. If not provided, the date from getStoryBookDateNow() will be used.
    @param granularity - The granularity to use for formatting.
    @param shortVersion - If true, the short version of the date will be returned.
*/
export const formatDateByGranularity = ({
  date = getStoryBookDateNow(),
  granularity,
  shortVersion,
}: {
  date?: DateTime<true>
  granularity: GranularityOption
  shortVersion?: boolean
}): string => {
  switch (granularity) {
    case 'Day':
      return shortVersion ? date.toFormat('d') : date.toFormat('d MMM yyyy')
    case 'Month':
      return shortVersion ? date.monthLong : `${date.monthLong} ${date.toFormat('yyyy')}`
    case 'Year':
      return date.toFormat('yyyy')
    default:
      throw new Error('Invalid granularity')
  }
}

/**
    This function is used to get the date that is used in storybook.
  * This is because we should use the same date in storybook to not have changes in snapshots.
*/
export const getStoryBookDateNow = () =>
  DateTime.fromISO('2023-08-21T12:00:00.000Z').setZone(
    getTimeZoneForPriceAreaCode(mockPriceArea),
  ) as DateTime<true>
