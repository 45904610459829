import type { ReactNode } from 'react'

import type { ContentSwitcherProps } from '@fortum/elemental-ui'
import { Box, breakpoints, fontSizes, fontWeights } from '@fortum/elemental-ui'

import * as $ from './styled'

export const WidgetTabSelector = <T extends string | number>(
  props: ContentSwitcherProps<T> & { isfullwidth?: string },
): ReactNode => {
  return (
    <Box display="flex" flexDirection="column">
      <$.ContentSwitcher
        {...props}
        alignSelf="stretch"
        height={props.isfullwidth ? '48px' : '40px'}
        minWidth={{ s: `${breakpoints.xs}px` }}
        fontWeight={fontWeights.default}
        fontSize={fontSizes.s}
      />
    </Box>
  )
}
